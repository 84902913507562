<!-- eslint-disable -->
<template>
  <v-container
    id="dashboard"
    fluidv
    tag="section"
    >
    <img id="myImg" center :src="require('../../assets/' + heropic)"/>
    <!-- <img id="myImg" center :src="heropic"> -->

  </v-container>
</template>
<script>
/* eslint-disable */
//************************
// JAVASCRIPT
//************************
export default {
  name: 'SiteHome',
  components: {
  },
data: () => ({
  // heropic: "http://localhost:8080/../../img/nashwoodshouse_edit200.7691509d.jpg"
  heropic: "nashwoodshouse_edit200.jpg",
  showalert: false,
}),

//***** NON-METHODS *******/
mounted () {
  // let q = document.getElementById("myImg").src 
  // console.log(q)
  // document.getElementById("myImg").src = "http://localhost:8080/../../img/nashwoodshouse_edit200.7691509d.jpg"
  // q = document.getElementById("myImg").src 
  // console.log(q)
}
}

</script>
